import React from 'react';
import SalesFigures from '../../components/SalesFigures/';
import Table from '../../components/Table/';

const data = {
    header: [
        "Ordernummer",
        "Datum",
        "Försändelse-id",
        "Märkning",
        "Leveransvecka",
        "Summa",
    ],
    body: [
        {
            items: [
                "SOB000223",
                "2019-12-10",
                "555994",
                "Mats Persson",
                "V3 2020",
                "5 000 SEK",
            ]
        },
        {
            items: [
                "SOB000223",
                "2019-12-10",
                "555994",
                "Mats Persson",
                "V3 2020",
                "5 000 SEK",
            ]
        },
        {
            items: [
                "SOB000223",
                "2019-12-10",
                "555994",
                "Mats Persson",
                "V3 2020",
                "5 000 SEK",
            ]
        },
    ]
};

class Home extends React.Component {
    render() {
        return (
            <div className="main-content">
                <h1>Välkommen tillbaka, Moa</h1>
                <SalesFigures />

                <div className="orders">
                    <h2>Mina senaste ordrar</h2>
                    <Table data={data} className="table-boxed" />
                </div>
            </div>
        );
    }
}

export default Home;