import React from 'react';

class NotFound extends React.Component {
    render() {
        return (
            <div className="main-content">
                <h1>Not Found</h1>
            </div>
        );
    }
}

export default NotFound;