import React from 'react';

class MediaCampaign extends React.Component {
    render() {
        return (
            <div className="main-content">
                <div className="content-primary">
                    <h1>KAMPANJMATERIAL</h1>
                    <p>...</p>
                </div>

            </div>
        );
    }
}

export default MediaCampaign;