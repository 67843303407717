import React from 'react';

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: 'moaal',
            password: 'abc123'
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    toggleEdit(event) {
        let pswField = document.querySelector('#password')

        pswField.readOnly = pswField.readOnly ? false : true;
        event.target.closest('.edit-box').classList.toggle('edit-box-disabled');
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.toggleEdit(event);
    }

    render() {
        return (
            <div className="main-content">
                <h1>Inställningar</h1>
                <div className="content-primary edit-box edit-box-disabled">
                    <div className="edit-box-header" onClick={this.toggleEdit}>
                        <div className="edit-box-title">Inloggningsuppgifter</div>
                        <div className="edit-box-toggle-content">Ändra lösenord</div>
                    </div>
                    <div className="edit-box-content">

                        <form className="form-complaints" onSubmit={this.handleSubmit}>
                            <label htmlFor="username">Användarnamn</label>
                            <input type="text" className="field field-text" id="username" name="username" value={this.state.username} onChange={this.handleChange} readOnly autoComplete="username" />

                            <label htmlFor="password">Lösenord</label>
                            <input type="password" className="field field-password" id="password" name="password" value={this.state.password} onChange={this.handleChange} readOnly autoComplete="new-password" />

                            <button type="submit" className="button-submit button-secondary">Spara</button>
                        </form>

                    </div>
                </div>

            </div>
        );
    }
}

export default Settings;