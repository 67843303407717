import React from 'react';
import NewsList from '../../components/News/';
import NewsArchiveList from '../../components/News/news-archive-list';

const newsItems = [
    {
        title: 'Fördelarna med en gjutjärnskamin',
        date: '2020-05-08',
        excerpt: 'När du ska välja en vedspis är det viktigt att fundera över vilket material du vill ha. Contura tillverkar kaminer i både gjutjärn och plåt. Båda materialen används för att skapa både moderna och tidl',
        name: '2020-05-08-fordelarna-med-en-gjutjarnskamin'
    },
    {
        title: 'Hur man skapar ett lagom utrymme i sitt hem',
        date: '2020-05-04',
        excerpt: 'Det svenska ordet lagom varit något av ett modeord i Europa, där många väljer att anta detta sätt att leva efter och integrera det i sina hem - men vad betyder lagom? Lagom är ett sinnestillstånd oc',
        name: '2020-05-04-hur-man-skapar-ett-lagom-utrymme-i-sitt-hem'
    },
    {
        title: 'Underhåll av din kamin och skorsten',
        date: '2020-03-11',
        excerpt: 'För att säkerställa bästa prestanda bör din kamin rengöras regelbundet. Din eldstad fungerar bäst när brännkammaren och skorstenen är så rena och smutsfria som möjligt. Här hittar du tipsen som hjälpe',
        name: '2020-03-11-underhall-av-din-kamin-och-skorsten'
    },
];

const newsArchiveItems = [
    {
        year: '2020',
        items: [
            {
                month: 'Maj',
                to: '/'
            },
            {
                month: 'Mars',
                to: '/'
            },
            {
                month: 'Februari',
                to: '/'
            },
        ],
    },
    {
        year: '2019',
        items: [
            {
                month: 'December',
                to: '/'
            },
            {
                month: 'Oktober',
                to: '/'
            },
            {
                month: 'Juli',
                to: '/'
            },
            {
                month: 'April',
                to: '/'
            },
        ],
    },
];

class News extends React.Component {
    render() {
        return (
            <div className="main-content">
                <div className="content-primary">
                    <h1>NYHETSARKIVET</h1>
                    <p>Här hittar du artiklar och nyhetsbrev från Contura. </p>
                </div>
                <div className="content-secondary news-block">
                    <div className="news-content news-content-latest-posts">
                        <NewsList items={newsItems} limit="10" />
                    </div>
                    <div className="news-archive">
                        <NewsArchiveList items={newsArchiveItems} />
                    </div>
                </div>
            </div>
        );
    }
}

export default News;