import React from 'react';
import PrintList from '../../components/Print/';

const printItems = [
    {
        img: 'placeholder-brochure.png',
        title: 'Contura huvudbroschyr',
        sku: '539456',
        package: 20,
        bundle: 10,
        to: '/',
        id: '001'
    },
    {
        img: 'placeholder-brochure.png',
        title: 'Conturas kasseter',
        sku: '345656',
        package: 20,
        bundle: 10,
        to: '/',
        id: '002'
    },
    {
        img: 'placeholder-brochure.png',
        title: 'Premodul',
        sku: '67888',
        package: 20,
        bundle: 10,
        to: '/',
        id: '003'
    },
    {
        img: 'placeholder-brochure.png',
        title: 'Radience',
        sku: '565777',
        bundle: 10,
        to: '/',
        id: '004'
    },
    {
        img: 'placeholder-brochure.png',
        title: 'CONTURA 800 STYLE',
        sku: '539456',
        package: 20,
        bundle: 10,
        to: '/',
        id: '005'
    },
    {
        img: 'placeholder-brochure.png',
        title: 'CONTURA 500 STYLE',
        sku: '345656',
        package: 20,
        bundle: 10,
        to: '/',
        id: '006'
    },
];


class Print extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cart: {},
            cartIsEmpty: true,
            submitStatus: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        let
            target = event.target,
            item = event.target.closest('.list-print-item'),
            field = event.target.closest('.field-select-box'),
            set = field.dataset.set,
            id = item.dataset.id,
            title = item.dataset.title,
            cart = this.state.cart;

        if(field.dataset.type === 'package') {
            cart[id] = {
                title: title,
                package: target.value,
                bundle: (cart[id] && cart[id].bundle) ? cart[id].bundle : 0,
                packageSet: set,
                bundleSet: (cart[id] && cart[id].bundleSet) ? cart[id].bundleSet : 0
            };
        }

        if(field.dataset.type === 'bundle') {
            cart[id] = {
                title: title,
                package: (cart[id] && cart[id].package) ? cart[id].package : 0,
                bundle: target.value,
                packageSet: (cart[id] && cart[id].packageSet) ? cart[id].packageSet : 0,
                bundleSet: set
            };
        }

        if(parseInt(cart[id].package) === 0 && parseInt(cart[id].bundle) === 0) {
            delete cart[id];
        }

        if(Object.keys(cart).length) {
            this.setState({cartIsEmpty: false});
        } else {
            this.setState({cartIsEmpty: true});
        }

        this.setState(cart);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({submitStatus: 'print-confirm'});
    }

    cart() {
        let cart = this.state.cart;
        return Object.keys(cart).map((key, index)  =>
            <div key={index}>
                <h3>{cart[key].title}</h3>
                {
                    cart[key].package ? (
                        <div>{cart[key].packageSet}st/kartong: {cart[key].package}st</div>
                    ) : null
                }
                {
                    cart[key].bundle ? (
                        <div>{cart[key].bundleSet}st/bunt: {cart[key].bundle}st</div>
                    ) : null
                }
            </div>
        );
    }

    render() {
        return (
            <div className={`main-content ${this.state.submitStatus}`}>
                <div className="content-primary content-primary-order">
                    <h1>BESTÄLL CONTURAS TRYCKSAKER</h1>
                    <p>Här hittar du Conturas aktuella trycksaker. Bild och artikelnummer förenklar vid beställning av trycksaker. Beställ inte för många broschyrer på en och samma gång, då de uppdateras med jämna mellanrum.</p>
                    <form className="form-print-list" onSubmit={this.handleSubmit}>
                        <PrintList items={printItems} handleChange={this.handleChange} />
                        <button type="submit" className="button-submit button-secondary" disabled={(this.state.cartIsEmpty)}>Fortsätt...</button>
                    </form>
                </div>

                <div className="content-primary content-primary-confirm">
                    <h1>Kontrollera din Beställning</h1>

                    <form className="form-print">
                        {this.cart()}
                        <div className="buttongroup">
                            <button type="button" className="button-tertiary" onClick={() => this.setState({ submitStatus: '' })}>Avbryt</button>
                            <button type="button" className="button-secondary" onClick={() => this.setState({ submitStatus: 'print-submitted' })}>Skicka beställning</button>
                        </div>
                    </form>
                </div>

                <div className="content-primary content-primary-feedback">
                    <h1>Tack, vi har mottagit din Beställning</h1>
                    <button type="button" className="button-secondary" onClick={() => this.setState({ submitStatus: '' })}>Skapa ny beställning</button>
                </div>
            </div>
        );
    }
}

export default Print;