import React from 'react';

class NewsList extends React.Component {
    listItems(items) {
        let blogPath = 'https://www.contura.eu/sv-se/inspiration--tips/las-var-kaminblogg/blogg/';

        return items.map((item, index) =>
            <a key={index} href={blogPath + item.name} className="list-news-item" rel="noopener noreferrer" target="_blank">
                <h3 className="list-news-title">{item.title}</h3>
                <time className="list-news-date" dateTime={item.date}>{item.date}</time>
                <div className="list-news-excerpt">{item.excerpt}</div>
            </a>
        );
    }

    render() {
        return (
            <div className="list-news">
                <div className="list-news-items">
                    {this.listItems(this.props.items)}
                </div>
            </div>
        );
    }
}

export default NewsList;