import React from 'react';
import { Link} from "react-router-dom";

function NewsArchive(props) {
    function listItems(items) {

        return items.map((item, index) =>
            <div key={index} className="list-newsarchive-item">
                <h4 className="list-newsarchive-title">{item.year}</h4>
                <div className="list-newsarchive-links">
                    {item.items.map((newsItem, index) =>
                        <Link key={index} to={newsItem.to} className="list-newsarchive-link">{newsItem.month}</Link>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="list-newsarchive">
            <div className="list-newsarchive-items">
                {listItems(props.items)}
            </div>
        </div>
    );
}

export default NewsArchive;