import React from 'react';
import ProductList from '../../components/ProductList/';
import CategoryProductList from '../../components/ProductList/category-product-list';

const myProducts = [
    {
        id: '001',
        title: 'Contura 35',
        img: 'placeholder-product.png',
        isLiveDisplay: false,
    },
    {
        id: '002',
        title: 'Contura 310',
        img: 'placeholder-product.png',
        isLiveDisplay: true,
    },
    {
        id: '003',
        title: 'Contura 510G ',
        img: 'placeholder-product.png',
        isLiveDisplay: false,
    },
    {
        id: '004',
        title: 'Contura 35',
        img: 'placeholder-product.png',
        isLiveDisplay: false,
    },
    {
        id: '005',
        title: 'Contura 310',
        img: 'placeholder-product.png',
        isLiveDisplay: true,
    },
    {
        id: '006',
        title: 'Contura 510G ',
        img: 'placeholder-product.png',
        isLiveDisplay: false,
    }
];

const products = [
    {
        title: 'Contura 20',
        items: [
            {
                id: '001',
                title: 'Contura 35',
                img: 'placeholder-product.png'
            },
            {
                id: '002',
                title: 'Contura 310',
                img: 'placeholder-product.png'
            },
            {
                id: '003',
                title: 'Contura 510G ',
                img: 'placeholder-product.png'
            },
            {
                id: '004',
                title: 'Contura 35',
                img: 'placeholder-product.png'
            },
            {
                id: '005',
                title: 'Contura 310',
                img: 'placeholder-product.png'
            },
            {
                id: '006',
                title: 'Contura 510G ',
                img: 'placeholder-product.png'
            }
        ]
    },
    {
        title: 'Contura 30',
        items: [
            {
                id: '007',
                title: 'Contura 35',
                img: 'placeholder-product.png'
            },
            {
                id: '008',
                title: 'Contura 310',
                img: 'placeholder-product.png'
            },
            {
                id: '009',
                title: 'Contura 510G ',
                img: 'placeholder-product.png'
            },
            {
                id: '010',
                title: 'Contura 35',
                img: 'placeholder-product.png'
            },
            {
                id: '011',
                title: 'Contura 310',
                img: 'placeholder-product.png'
            },
            {
                id: '012',
                title: 'Contura 510G ',
                img: 'placeholder-product.png'
            }
        ]
    },
    {
        title: 'Contura 50',
        items: [
            {
                id: '013',
                title: 'Contura 35',
                img: 'placeholder-product.png'
            },
            {
                id: '014',
                title: 'Contura 310',
                img: 'placeholder-product.png'
            },
            {
                id: '015',
                title: 'Contura 510G ',
                img: 'placeholder-product.png'
            },
            {
                id: '016',
                title: 'Contura 35',
                img: 'placeholder-product.png'
            },
            {
                id: '017',
                title: 'Contura 310',
                img: 'placeholder-product.png'
            },
            {
                id: '018',
                title: 'Contura 510G ',
                img: 'placeholder-product.png'
            }
        ]
    },
];


class ProductsCurrent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddProducts: false,
        };

        this.toggleShowAddProducts = this.toggleShowAddProducts.bind(this);
    }

    toggleShowAddProducts() {
        this.setState({
            showAddProducts: (this.state.showAddProducts ? false : true)
        });
    }

    render() {
        return (
            <div className={`main-content ${this.state.showAddProducts ? 'main-content-add-products' : ''}`}>
                <div className="content-primary">
                    <div className="selected-products">
                        <h1>Produkter i min utställning</h1>
                        <p>Här kan ni lägga till och ta bort produkter som ni har i er butik/utställning. De produkter ni har lagt till syns nedan. Produkterna används på våra webbsidor i samband med att man söker efter återförsäljare.</p>
                    </div>
                    <div className="add-products">
                        <h1>Lägg till produkter i er utställning</h1>
                        <p>Här kan du lägga till de produkter som visas i er utställning. Du kan även specificera om de är eldningsbara eller ej.</p>
                    </div>
                    <button type="button" className="button-secondary" onClick={this.toggleShowAddProducts}>{`${this.state.showAddProducts ? 'Produkter i min utställning' : 'Lägg till produkter'}`}</button>

                </div>
                <div className="content-secondary selected-products">
                    <ProductList items={myProducts} />
                </div>
                <div className="content-secondary add-products">
                    <CategoryProductList items={products} toggleShowAddProducts={this.toggleShowAddProducts}/>
                </div>
            </div>
        );
    }
}

export default ProductsCurrent;