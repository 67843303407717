import React from 'react';
import { ReactComponent as IconToggle } from '../../../images/icons/plus-circle.svg'

class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: 'moa.almarsson@nibe.se',
            firstname: 'Moa',
            lastname: 'Almarsson',
            phoneOffice: '12345678',
            phoneMobile: '90123456',
            newEmail: '',
            newFirstname: '',
            newLastname: '',
            newPhoneOffice: '',
            newPhoneMobile: '',
        };
        this.toggleEdit = this.toggleEdit.bind(this);
        this.update = this.update.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    toggleEdit(event) {
        let
            fields = document.querySelectorAll('.field'),
            editBox = event.target.closest('.edit-box');

        for (var i = 0; i < fields.length; i++) {
            fields[i].readOnly = fields[i].readOnly ? false : true;
        }
        editBox.classList.toggle('edit-box-disabled');

        if(editBox.classList.contains('edit-box-disabled')) {
            this.update()
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    update() {

    }

    render() {
        return (
            <div className="main-content">

                <h1>Min profil</h1>
                <div className="content-primary edit-box edit-box-disabled">
                    <div className="edit-box-header" onClick={this.toggleEdit}>
                        <div className="edit-box-title">Kontaktuppgifter</div>
                        <div className="edit-box-btn-edit">Redigera</div>
                        <div className="edit-box-btn-update">Spara</div>
                    </div>
                    <div className="edit-box-content">

                        <form className="form-account">
                            <label htmlFor="email">E-postadress</label>
                            <input type="email" className="field field-text" id="email" name="email" value={this.state.email} onChange={this.handleChange} readOnly />

                            <label htmlFor="firstname">Förnamn</label>
                            <input type="text" className="field field-text" id="firstname" name="firstname" value={this.state.firstname} onChange={this.handleChange} readOnly />

                            <label htmlFor="lastname">Efternamn</label>
                            <input type="text" className="field field-text" id="lastname" name="lastname" value={this.state.lastname} onChange={this.handleChange} readOnly />

                            <label htmlFor="phone-office">Telefonnummer kontor</label>
                            <input type="tel" className="field field-text" id="phone-office" name="phone-office" value={this.state.phoneOffice} onChange={this.handleChange} readOnly />

                            <label htmlFor="phone-mobile">Telefonnummer mobil</label>
                            <input type="tel" className="field field-text" id="phone-mobile" name="phone-mobile" value={this.state.phoneMobile} onChange={this.handleChange} readOnly />

                        </form>

                    </div>
                </div>

                <div className="content-primary edit-box edit-box-new-account edit-box-disabled">
                    <div className="edit-box-header" onClick={this.toggleEdit}>
                        <div className="edit-box-title">Lägg till användare</div>
                        <div className="edit-box-toggle-content"><IconToggle /></div>
                    </div>
                    <div className="edit-box-content">

                        <form className="form-new-account" onSubmit={this.handleSubmit}>
                            <label htmlFor="new-email">E-postadress</label>
                            <input type="email" className="field field-text" id="new-email" name="new-email" value={this.state.newEmail} onChange={this.handleChange} readOnly />

                            <label htmlFor="new-firstname">Förnamn</label>
                            <input type="text" className="field field-text" id="new-firstname" name="new-firstname" value={this.state.newFirstname} onChange={this.handleChange} readOnly />

                            <label htmlFor="new-lastname">Efternamn</label>
                            <input type="text" className="field field-text" id="new-lastname" name="new-lastname" value={this.state.newLastname} onChange={this.handleChange} readOnly />

                            <label htmlFor="new-phone-office">Telefonnummer kontor</label>
                            <input type="tel" className="field field-text" id="new-phone-office" name="new-phone-office" value={this.state.newPhoneOffice} onChange={this.handleChange} readOnly />

                            <label htmlFor="new-phone-mobile">Telefonnummer mobil</label>
                            <input type="tel" className="field field-text" id="new-phone-mobile" name="new-phone-mobile" value={this.state.newPhoneMobile} onChange={this.handleChange} readOnly />

                            <button type="submit" className="button-submit button-primary">Spara</button>
                        </form>

                    </div>
                </div>

                <div className="content-primary edit-box edit-box-disabled">
                    <div className="edit-box-header">
                        <div className="edit-box-title">Kontaktuppgifter till säljrepresentant</div>
                    </div>
                    <div className="edit-box-content">

                        <form className="form-sales-reps" onSubmit={this.handleSubmit}>
                            <label htmlFor="email">E-postadress</label>
                            <input type="email" className="field field-text" id="email" name="email" value={this.state.email} onChange={this.handleChange} readOnly />

                            <label htmlFor="firstname">Förnamn</label>
                            <input type="text" className="field field-text" id="firstname" name="firstname" value={this.state.firstname} onChange={this.handleChange} readOnly />

                            <label htmlFor="lastname">Efternamn</label>
                            <input type="text" className="field field-text" id="lastname" name="lastname" value={this.state.lastname} onChange={this.handleChange} readOnly />

                            <label htmlFor="phone-office">Telefonnummer kontor</label>
                            <input type="tel" className="field field-text" id="phone-office" name="phone-office" value={this.state.phoneOffice} onChange={this.handleChange} readOnly />

                            <label htmlFor="phone-mobile">Telefonnummer mobil</label>
                            <input type="tel" className="field field-text" id="phone-mobile" name="phone-mobile" value={this.state.phoneMobile} onChange={this.handleChange} readOnly />

                            <button type="submit" className="button-submit button-secondary">Spara</button>
                        </form>

                    </div>
                </div>
            </div>
        );
    }
}

export default Account;