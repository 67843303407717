import React from 'react';

class Campaigns extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            submitStatus: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            submitStatus: 'main-content-show-feedback'
        });
    }

    render() {
        return (
            <div className={`main-content ${this.state.submitStatus}`}>
                <div className="content-primary content-primary-signup">
                    <h1>ANMÄL ER TILL ÅRETS KAMPANJ</h1>

                    <form className="form-campaigns" onSubmit={this.handleSubmit}>
                        <input type="text" className="field field-text" id="name" name="name" placeholder="Namn" value={this.state.name} onChange={this.handleChange} />

                        <input type="email" className="field field-text" id="email" name="email" placeholder="E-postadress" value={this.state.email} onChange={this.handleChange} />

                        <button type="submit" className="button-submit button-secondary">Skicka</button>
                    </form>
                </div>

                <div className="content-primary content-primary-feedback">
                    <h1>Tack</h1>
                </div>
            </div>
        );
    }
}

export default Campaigns;