import React from 'react';
import Table from '../../components/Table/';

const data = {
    header: [
        "Skapad",
        "Reklamationsnummer",
        "Märkning",
        "Serienummer",
        "Status",
    ],
    body: [
        {
            status: 'open',
            items: [
                "2019-12-10",
                "SOB000223",
                "Mats Persson",
                "5559945599",
                "Obehandlad",
            ]
        },
        {
            status: 'in-progress',
            items: [
                "2019-12-10",
                "SOB000223",
                "Mats Persson",
                "5559945599",
                "Behandlas",
            ]
        },
        {
            status: 'done',
            items: [
                "2019-12-10",
                "SOB000223",
                "Mats Persson",
                "5559945599",
                "Utförd",
            ]
        },
    ]
};

class Complaints extends React.Component {
    render() {
        return (
            <div className="main-content">
                <div className="primary-content">
                    <h1>Mina reklamationer</h1>
                    <Table data={data} className="table-default" limit="2" />
                </div>

            </div>
        );
    }
}

export default Complaints;