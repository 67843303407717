import React from 'react';
import { ReactComponent as ConturaLogo } from '../../../images/contura-logo.svg'
import ToggleMobileMenu from '../../components/ToggleMobileMenu/';
import NavigationList from '../../components/NavigationList/';

function Navigation() {
    return (
        <div className="navigation">
            <div className="navigation-content">
                <div className="navigation-header">
                    <a href="/" className="navigation-logo">
                        <ConturaLogo />
                    </a>
                    <ToggleMobileMenu />
                </div>
                <NavigationList />
            </div>
        </div>
    );
}

export default Navigation;