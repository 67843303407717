import React from 'react';
import SalesFigures from '../../components/SalesFigures/';

class Finance extends React.Component {
    render() {
        return (
            <div className="main-content">
                <h1>Statistik</h1>
                <SalesFigures />
            </div>
        );
    }
}

export default Finance;