import React from 'react';
import CategoryList from '../../components/CategoryList/';

const downloadItems = [
    {
        title: 'Dokument',
        items: [
            {
                title: 'Prislista 2019:1',
                to: '/downloads/23232334343',
            },
            {
                title: 'Prislista 2019:2',
                to: '/downloads/23232334343',
            },
            {
                title: 'Prislista Contura Radience',
                to: '/downloads/23232334343',
            },
        ]
    },
    {
        title: 'Beställningsunderlag',
        items: [
            {
                title: 'Prislista 2019:1',
                to: '/downloads/23232334343',
            },
            {
                title: 'Prislista 2019:2',
                to: '/downloads/23232334343',
            },
            {
                title: 'Prislista Contura Radience',
                to: '/downloads/23232334343',
            },
        ]
    },
    {
        title: 'Prestandadeklarationer',
        items: [
            {
                title: 'Prislista 2019:1',
                to: '/downloads/23232334343',
            },
            {
                title: 'Prislista 2019:2',
                to: '/downloads/23232334343',
            },
            {
                title: 'Prislista Contura Radience',
                to: '/downloads/23232334343',
            },
        ]
    },
];

class Media extends React.Component {
    render() {
        return (
            <div className="main-content">
                <div className="content-primary">
                    <h1>LADDA NER DOKUMENT OCH BESTÄLLNINGSUNDERLAG</h1>
                </div>
                <div className="content-secondary">
                    <CategoryList items={downloadItems} />
                </div>
            </div>
        );
    }
}

export default Media;