import React from 'react';

import Account from '../../pages/Account/';
import Campaigns from '../../pages/MyPages/campaigns';
import Complaints from '../../pages/Complaints/';
import ComplaintCreate from '../../pages/Complaints/create';
import Finance from '../../pages/Finance/';
import Header from '../../components/Header/';
import Home from '../../pages/Home/';
import Invoices from '../../pages/Finance/invoices';
import Media from '../../pages/Media/';
import MediaPrint from '../../pages/Media/print';
import MediaSigns from '../../pages/Media/signs';
import MediaHighResolution from '../../pages/Media/high-resolution';
import MediaVideo from '../../pages/Media/video';
import MediaCampaign from '../../pages/Media/campaign';
import Navigation from '../../components/Navigation/';
import News from '../../pages/MyPages/news';
import NotFound from '../../pages/NotFound/';
import Orders from '../../pages/Orders/';
import OrderHistory from '../../pages/Orders/order-history';
import OrderTracking from '../../pages/Orders/order-tracking';
import Products from '../../pages/MyPages/';
import Settings from '../../pages/Settings/';

function PageContent(props) {
    let path = props.pathname === '/' ? 'home' : props.pathname.replace(/\//g, '')

    function Main() {
        switch(path) {
            case 'account':
                return <Account />;
            case 'campaigns':
                return <Campaigns />;
            case 'complaints':
                return <Complaints />;
            case 'complaint-create':
                return <ComplaintCreate />;
            case 'finance':
                return <Finance />;
            case 'home':
                return <Home />;
            case 'invoices':
                return <Invoices />;
            case 'media':
                return <Media />;
            case 'media-campaign':
                return <MediaCampaign />;
            case 'media-high-resolution':
                return <MediaHighResolution />;
            case 'media-print':
                return <MediaPrint />;
            case 'media-signs':
                return <MediaSigns />;
            case 'media-video':
                return <MediaVideo />;
            case 'news':
                return <News />;
            case 'NotFound':
                return <NotFound />;
            case 'orders':
                return <Orders />;
            case 'order-history':
                return <OrderHistory />;
            case 'order-tracking':
                return <OrderTracking />;
            case 'products':
                return <Products />;
            case 'settings':
                return <Settings />;
            default:
                return <NotFound />;
        }
    }

    function closeMobileMenu() {
        document.documentElement.classList.remove('has-visible-mobile-menu');
    }

    return (
        <div className={`page-content page-content-${path}`}>
            <div className="main-container">
                <Header />
                <main>
                    {Main(props.name)}
                </main>
            </div>
            <div className="overlay" onClick={closeMobileMenu}></div>
            <Navigation />
        </div>
    );
}

export default PageContent;