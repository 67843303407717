import React from 'react';
import { ReactComponent as IconToggle } from '../../../images/icons/arrow-down-orange.svg'
import ProductList from '../../components/ProductList/';

function CategoryProductList(props) {

    function itemToggle(event) {
        let self = event.target;

        self.closest('.list-category-item').classList.toggle('list-category-item-has-visible-content');
    }

    function listItems(props) {

        return props.items.map((item, index) =>
            <div key={index} className="list-category-item">
                <div className="list-category-title" onClick={itemToggle} >{item.title}<span className="icon-toggle"><IconToggle /></span></div>
                <div className="list-category-content">
                    <ProductList items={item.items} index={index} add={true} toggleShowAddProducts={props.toggleShowAddProducts} />
                </div>
            </div>
        );
    }

    return (
        <div className="list-category">
            <div className="list-category-items">
                {listItems(props)}
            </div>
        </div>
    );
}

export default CategoryProductList;