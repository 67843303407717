import React from 'react';
import { ReactComponent as ConturaLogo } from '../../../images/contura-logo.svg'
import ToggleMobileMenu from '../../components/ToggleMobileMenu/';
import User from '../../components/User/';

function Header() {
    return (
        <header className="site-header">
            <div className="site-header-left">
                <ToggleMobileMenu />
                <a href="/" className="header-logo">
                    <ConturaLogo />
                </a>
            </div>
            <User />
        </header>
    );
}

export default Header;