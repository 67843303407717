import React from 'react';
import Icon from '../../components/Icon/';
import { Link } from "react-router-dom";

function User() {

    // Close dropdown menu on click outside
    document.addEventListener('click', function(event) {
        let dropDown = document.querySelector('.user');
        if(dropDown) {
            let
                dropDownVisible = dropDown.classList.contains('user-has-visible-user-menu'),
                isClickInside = dropDown.contains(event.target);

            if (dropDownVisible && !isClickInside) {
                dropDown.classList.toggle('user-has-visible-user-menu');
            }
        }
    });

    function toggleUserMenu() {
        document.querySelector('.user').classList.toggle('user-has-visible-user-menu');
    }

    function resetMenu() {
        let menuItemCurrent = document.querySelectorAll('.menu-item-current');

        for (var i = 0; i < menuItemCurrent.length; i++) {
            menuItemCurrent[i].classList.remove('menu-item-current');
        }

        toggleUserMenu();

        localStorage.removeItem('navCurrentParentId');
        localStorage.removeItem('navCurrentChildId');
    }

    function signOut() {
        localStorage.removeItem('isAuthenticated');
        resetMenu();
    }

    return (
        <div className="user">
            <div className="user-info" onClick={toggleUserMenu}>
                <div className="user-avatar"><Icon name="User" /></div>
                <div className="user-name">Moa Almarsson</div>
                <div className="toggle-user-menu"></div>
            </div>

            <div className="user-menu">
                <div className="user-menu-item">
                    <Link to="/account" onClick={resetMenu} className="user-menu-link"><Icon name="Account" />Profil</Link>
                </div>
                <div className="user-menu-item">
                    <Link to="/settings" onClick={resetMenu} className="user-menu-link"><Icon name="Settings" />Inställningar</Link>
                </div>
                <div className="user-menu-item">
                    <Link to="/login" onClick={signOut} className="user-menu-link"><Icon name="SignOut" />Logga ut</Link>
                </div>
            </div>
        </div>
    );
}

export default User;