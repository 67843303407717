import React from 'react';
import { Link } from "react-router-dom";
import Icon from '../../components/Icon/';

const naivgationItems = [
    {
        title: 'Hem',
        to: '/',
        icon: 'Home'
    },
    {
        title: 'Lägg ny order',
        to: '/order-add',
        icon: 'AddOrder'
    },
    {
        title: 'Mina ordrar',
        icon: 'Orders',
        children: [
            {
                title: 'Inneliggande',
                to: '/orders',
            },
            {
                title: 'Orderhistorik',
                to: '/order-history',
            },
            {
                title: 'Spåra leverans',
                to: '/order-tracking',
            },
        ]
    },
    {
        title: 'Mina sidor',
        icon: 'MyPages',
        children: [
            {
                title: 'kampanjer',
                to: '/campaigns',
            },
            {
                title: 'Mina produkter',
                to: '/products',
            },
            {
                title: 'Nyhetsarkiv',
                to: '/news',
            },
        ]
    },
    {
        title: 'Ekonomi',
        icon: 'Economy',
        children: [
            {
                title: 'Statistik',
                to: '/finance',
            },
            {
                title: 'fakturaöversikt',
                to: '/invoices',
            },
        ]
    },
    {
        title: 'Reklamation',
        icon: 'Complaints',
        children: [
            {
                title: 'Skapa reklamation',
                to: '/complaint-create',
            },
            {
                title: 'Mina reklamationer',
                to: '/complaints',
            },
        ]
    },
    {
        title: 'Media',
        icon: 'Media',
        children: [
            {
                title: 'Beställ trycksaker',
                to: '/media-print',
            },
            {
                title: 'Dokument',
                to: '/media',
            },
            {
                title: 'Produktskyltar',
                to: '/media-signs',
            },
            {
                title: 'Högupplösta bilder',
                to: '/media-high-resolution',
            },
            {
                title: 'Rörlig media',
                to: '/media-video',
            },
            {
                title: 'Kampanjmaterial',
                to: '/media-campaign',
            },
        ]
    },
];

let
    navCurrentParentId = localStorage.getItem('navCurrentParentId'),
    navCurrentChildId = localStorage.getItem('navCurrentChildId');

function NavigationList() {

    function itemCurrent(event) {
        let
            self = event.target,
            openItems = self.closest('.menu').querySelectorAll('.menu-item-current'),
            menuItem = self.parentElement,
            menuLevelOne = menuItem.classList.contains('menu-item--has-children'),
            selfHasOpenItems = menuItem.classList.contains('menu-item-current');

        if(!selfHasOpenItems) {
            for (var i = 0; i < openItems.length; i++) {
                openItems[i].classList.remove('menu-item-current');
            }
        }

        if(menuLevelOne) {
            menuItem.classList.toggle('menu-item-current');
            localStorage.setItem('navCurrentParentId', menuItem.dataset.id)
            localStorage.removeItem('navCurrentChildId');
        } else {
            menuItem.classList.add('menu-item-current');
            localStorage.setItem('navCurrentChildId', menuItem.dataset.id)
        }
    }

    function listItems(items) {
        return (
            items.map((item, index) =>
                // If has children
                item.children ? (
                    <div key={index} className={`menu-item menu-item--level-1 menu-item--has-children ${parseInt(navCurrentParentId) === (index + 1) ? 'menu-item-current' : ''}`} data-id={(index + 1)}>
                        <span onClick={itemCurrent} className="menu-item-link menu-item-link--level-1"><Icon name={item.icon} />{item.title}</span>

                        <span className="menu-toggle-child" onClick={itemCurrent}></span>
                        <div className="menu menu--level-2">
                            {
                                item.children.map((child, childIndex) =>
                                    <div key={childIndex} className={`menu-item menu-item--level-2 ${navCurrentChildId === ((index + 1) +'.'+ (childIndex + 1)) ? 'menu-item-current' : ''}`} data-id={(index + 1) +'.'+ (childIndex + 1)}>
                                        <Link to={child.to} onClick={itemCurrent} className="menu-item-link menu-item-link--level-2">{child.title}{}</Link>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                ) : (
                    <div key={item.title} className={`menu-item menu-item--level-1 ${parseInt(navCurrentParentId) === (index + 1) ? 'menu-item-current' : ''}`} data-id={(index + 1)}>
                        <Link to={item.to} onClick={itemCurrent} className="menu-item-link menu-item-link--level-1"><Icon name={item.icon} />{item.title}</Link>
                    </div>
                )
            )
        )
    }

    return (
        <div className="menu menu--level-1">
            {listItems(naivgationItems)}
        </div>
    );
}

export default NavigationList;