import React from 'react';
import IconLoading from '../../../images/running-dog.gif'

class OrderAdd extends React.Component {
    render() {
        return (
            <div className="page-content page-content-order-add">
                <div className="main-container">
                    <main>
                        <div className="main-content">
                            <h1>Du skickas nu över conturas Sales portal</h1>
                            <p>Det kan ta någon sekund...</p>
                            <span className="icon-loading"><img src={IconLoading} alt="Vänta..." /></span>
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}

export default OrderAdd;