import React from 'react';
import CategoryList from '../../components/CategoryList/';

const downloadItems = [
    {
        title: 'Contura 20',
        items: [
            {
                title: 'Prislista 2019:1',
                to: '/downloads/23232334343',
            },
            {
                title: 'Prislista 2019:2',
                to: '/downloads/23232334343',
            },
            {
                title: 'Prislista Contura Radience',
                to: '/downloads/23232334343',
            },
        ]
    },
    {
        title: 'Contura 30',
        items: [
            {
                title: 'Prislista 2019:1',
                to: '/downloads/23232334343',
            },
            {
                title: 'Prislista 2019:2',
                to: '/downloads/23232334343',
            },
            {
                title: 'Prislista Contura Radience',
                to: '/downloads/23232334343',
            },
        ]
    },
    {
        title: 'Contura 50',
        items: [
            {
                title: 'Prislista 2019:1',
                to: '/downloads/23232334343',
            },
            {
                title: 'Prislista 2019:2',
                to: '/downloads/23232334343',
            },
            {
                title: 'Prislista Contura Radience',
                to: '/downloads/23232334343',
            },
        ]
    },
];

class MediaHighResolution extends React.Component {
    render() {
        return (
            <div className="main-content">
                <div className="content-primary">
                    <h1>LADDA NER FRILAGDA PRODUKTBILDER OCH HÖGUPPLÖSTA MILJÖBILDER</h1>
                    <p>Energimärkningen hjälper dig att välja den braskamin som har högst verkningsgrad med lägsta driftkostnad och inverkan på miljön. Här hittar du energiklassifikationerna för våra vedeldade produkter.</p>
                </div>
                <div className="content-secondary">
                    <CategoryList items={downloadItems} />
                </div>
            </div>
        );
    }
}

export default MediaHighResolution;