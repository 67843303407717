import React from 'react';
import Table from '../../components/Table/';
import { ReactComponent as IconSearch } from '../../../images/icons/search.svg'

const data = {
    header: [
        "Ordernummer",
        "Datum",
        "Försändelse ID",
        "Märkning",
        "Leverans från fabrik",
        "Summa",
    ],
    body: [
        {
            items: [
                "SOB000223",
                "2019-12-10",
                "1235",
                "Mats Persson",
                "V3 2020",
                "5 000 SEK",
            ]
        },
        {
            items: [
                "SOB000223",
                "2019-12-10",
                "1235",
                "Mats Persson",
                "V3 2020",
                "5 000 SEK",
            ]
        },
        {
            items: [
                "SOB000223",
                "2019-12-10",
                "1235",
                "Mats Persson",
                "V3 2020",
                "5 000 SEK",
            ]
        },
    ]
};

class OrderHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
    }
    render() {
        return (
            <div className="main-content">
                <div className="primary-content">
                    <h1>Orderhistorik</h1>

                    <form className="form-order-history" onSubmit={this.handleSubmit}>
                        <div className="field-search">
                            <input type="search" className="field field-search-input" id="search" name="search" placeholder="Ordernummer eller ordermärkning" value={this.state.search} onChange={this.handleChange} />
                            <button type="submit" className="button-search"><IconSearch /></button>
                        </div>

                        <div className="filter-table field field-select">
                            <select className="field-select-input">
                                <option>Filtrera</option>
                                <option>...</option>
                                <option>...</option>
                            </select>
                        </div>
                    </form>

                    <Table data={data} className="table-default" limit="2" />
                </div>
            </div>
        );
    }
}

export default OrderHistory;