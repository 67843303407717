import React from 'react';
import VideoList from '../../components/Video/';

const videoItems = [
    {
        img: 'placeholder-video.jpg',
        title: 'Contura 300',
        meta: '1920x2080 .mp4 (ZIP)',
        to: '/',
    },
    {
        img: 'placeholder-video.jpg',
        title: 'CONTURA 600 STYLE',
        meta: '1920x1080 .mp4 (ZIP)',
        to: '/',
    },
    {
        img: 'placeholder-video.jpg',
        title: 'CONTURA 500 STYLE',
        meta: '1920x2080 .mp4 (ZIP)',
        to: '/',
    },
    {
        img: 'placeholder-video.jpg',
        title: 'SMARTA FUNKTIONER',
        meta: '1920x2080 .mp4 (ZIP)',
        to: '/',
    },
    {
        img: 'placeholder-video.jpg',
        title: 'ELDNINGSFILM',
        meta: '1920x2080 .mp4 (ZIP)',
        to: '/',
    },
    {
        img: 'placeholder-video.jpg',
        title: 'CATLADY',
        meta: '1920x2080 .mp4 (ZIP)',
        to: '/',
    },
];

class Video extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    render() {
        return (
            <div className="main-content">
                <div className="content-primary">
                    <h1>LADDA NER FILMER OCH VIDEOKLIPP FRÅN CONTURA</h1>
                    <p>Ladda ner filmer med våra braskaminer för användning i din butik eller på din hemsida. Du får redigera och korta ner filmerna, men du måste se till att Conturas riktlinjer med korrekt logotype, färger, typsnitt etc följs och inte manipuleras.</p>
                </div>
                <div className="content-secondary">
                    <VideoList items={videoItems} />
                </div>
            </div>
        );
    }
}

export default Video;