import React from 'react';

function Print(props) {

    function listItems(items) {
        return items.map((item, index) =>
            <div key={index} className="list-print-item"  data-id={item.id} data-title={item.title}>
                <div className="list-print-image"><img src={require('../../../images/' + item.img)} alt="" /></div>
                <div className="list-print-content">
                    <h3 className="list-print-title">{item.title}</h3>
                    <p className="list-print-sku">Artnr: {item.sku}</p>
                    { item.package ? (
                        <div className="field-select-box" data-type="package" data-set={item.package}>
                            <label htmlFor={`package-${index}`}>{item.package} st/kartong</label>
                            <select id={`package-${index}`} name={`package-${index}`} className="field-select-box-input" onChange={props.handleChange}>
                                <option>0</option>
                                <option>1</option>
                                <option>10</option>
                                <option>100</option>
                            </select>
                        </div>
                    ) : null }
                    { item.bundle ? (
                        <div className="field-select-box" data-type="bundle" data-set={item.bundle}>
                            <label htmlFor={`bundle-${index}`}>{item.bundle} st/bunt</label>
                            <select id={`bundle-${index}`} name={`bundle-${index}`} className="field-select-box-input" onChange={props.handleChange}>
                                <option>0</option>
                                <option>1</option>
                                <option>10</option>
                                <option>100</option>
                            </select>
                        </div>
                    ) : null }
                </div>
            </div>
        );
    }

    return (
        <div className="list-print">
            <div className="list-print-items">
                {listItems(props.items)}
            </div>
        </div>

    );
}

export default Print;