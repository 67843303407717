import React from 'react';
import Icon from '../../components/Icon/';

class Table extends React.Component {

    openLink(link) {
        let url = process.env.PUBLIC_URL + link;
        window.open(url, '_blank');
    }

    showMore() {

    }

    render() {
        let
            cellsLength = this.props.data.header.length,
            showMore = true,
            hasStatusNotice = this.props.data.body.filter(function(item) {
                return item.status === "notice";
            }).length;

        return (
            <table className={`${this.props.className} table-cols-${cellsLength} ${hasStatusNotice ? 'table-has-status-notice' : ''}`}>
                <thead>
                    <tr>
                        { this.props.data.header.map((item, index) =>
                            <th key={index}><p>{item}</p></th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.body.map((bodyItem, index) =>
                        <tr
                            key={index}
                            onClick={() => bodyItem.to && this.openLink(bodyItem.to)}
                            className={
                                (bodyItem.status ? 'status status-'+ bodyItem.status : null) +
                                (bodyItem.to ? ' table-row-has-link' : null)
                            }
                        >
                            {bodyItem.items.map((bodyItem, index) =>
                                <td key={index}><p>{bodyItem}</p></td>
                            )}
                        </tr>
                    )}
                </tbody>
                { showMore ? (
                    <tfoot>
                        <tr>
                            <td
                                className="tfoot-show-more"
                                onClick={this.showMore}
                            >
                                <p>Visa äldre ordrar</p>
                                <Icon name={'ShowMore'} />
                            </td>
                        </tr>
                    </tfoot>
                ) : null
                }
            </table>
        );
    }
}

export default Table;