import React from 'react';
import Table from '../../components/Table/';

const data = {
    header: [
        "Fakturanummer",
        "Ordernummer",
        "Märkning",
        "Datum",
        "Förfallodatum",
        "Summa",
        "Status",
    ],
    body: [
        {
            status: 'paid',
            items: [
                "67954",
                "SOB000223",
                "Mats Persson",
                "2019-11-10",
                "2019-12-10",
                "5 000 SEK",
                "Betald",
            ]

        },
        {
            status: 'unpaid',
            items: [
                "67954",
                "SOB000223",
                "Mats Persson",
                "2019-11-10",
                "2019-12-10",
                "5 000 SEK",
                "Obetald",
            ]
        },
        {
            status: 'overdue',
            items: [
                "67954",
                "SOB000223",
                "Mats Persson",
                "2019-11-10",
                "2019-12-10",
                "5 000 SEK",
                "Förfallen",
            ]
        },
    ]
};

class Invoices extends React.Component {
    render() {
        return (
            <div className="main-content">
                <div className="primary-content">
                    <h1>Fakturaöversikt</h1>
                    <Table data={data} className="table-default" limit="2" />
                </div>

            </div>
        );
    }
}

export default Invoices;