import React from 'react';
import Icon from '../../components/Icon/';
import { Link } from "react-router-dom";

function Video(props) {

    function listItems(items) {
        return items.map((item, index) =>
            <Link key={index} to={item.to} className="list-video-item">
                <div className="list-video-image"><img src={require('../../../images/' + item.img)} alt="" /></div>
                <div className="list-video-content">
                    <h3 className="list-video-title">{item.title}</h3>
                    <p className="list-video-meta">Format: {item.meta}</p>
                    <p className="list-video-subtitle">Ladda ner</p>
                    <Icon name="Download" />
                </div>
            </Link>
        );
    }

    return (
        <div className="list-video">
            <div className="list-video-items">
                {listItems(props.items)}
            </div>
        </div>

    );
}

export default Video;