import React from 'react';
import { ReactComponent as ConturaLogo } from '../../../images/contura-logo.svg'

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            isAuthMessage: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.setState({
            isAuthMessage: false
        })
    }

    handleSubmit(event) {
        event.preventDefault();

        if(this.state.username === 'nibe' && this.state.password === 'abc123') {
            localStorage.setItem('isAuthenticated', true);
            window.location.replace('/');
        } else {
            this.setState({
                isAuthMessage: true
            })
        }
    }

    render() {
        return (
            <div className="page-content page-content-login">
                <div className="main-container">
                    <main>
                        <div className="main-content">
                            <div className="login-logo"><ConturaLogo /></div>
                            <h1>VÄLKOMMEN TILL CONTURAS ÅTERFÖRSÄLJARPORTAL</h1>
                            <p>Återförsäljarportalen är ett verktyg för dig som har en butik med
                            Conturas produkter. Du kan bland annat uppdatera dina kontaktuppgifter,
                            beställa produkter och anmäla dig till kampanjer.</p>

                            <h2>Logga in</h2>
                            <form className="form-login" onSubmit={this.handleSubmit}>
                                { this.state.isAuthMessage ? (
                                    <p className="error-message">Fel användarnamn/lösenord</p>
                                ) : null
                                }
                                <input type="text" className="field field-text" name="username" value={this.state.username} onChange={this.handleChange} placeholder="Användare" autoComplete="username" />
                                <input type="password" className="field field-password" name="password" value={this.state.password} onChange={this.handleChange} onKeyPress={this.onKeyPress} placeholder="Lösenord" autoComplete="current-password" />
                                <button type="submit" className="button button-primary">Logga in</button>
                            </form>
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}

export default Login;