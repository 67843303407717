import React from 'react';
import { ReactComponent as IconHome } from '../../../images/icons/home-2.svg'
import { ReactComponent as IconAddOrder } from '../../../images/icons/add-plus.svg'
import { ReactComponent as IconDownload } from '../../../images/icons/download.svg'
import { ReactComponent as IconOrders } from '../../../images/icons/delivery-cart.svg'
import { ReactComponent as IconMyPages } from '../../../images/icons/bookmark.svg'
import { ReactComponent as IconEconomy } from '../../../images/icons/bar-chart-statistics.svg'
import { ReactComponent as IconComplaints } from '../../../images/icons/chat.svg'
import { ReactComponent as IconMedia } from '../../../images/icons/picture-image.svg'
import { ReactComponent as IconUser } from '../../../images/icons/user-circle-o.svg'
import { ReactComponent as IconAccount } from '../../../images/icons/user.svg'
import { ReactComponent as IconSettings } from '../../../images/icons/cog.svg'
import { ReactComponent as IconPassword } from '../../../images/icons/password.svg'
import { ReactComponent as IconShowMore } from '../../../images/icons/plus-circle.svg'
import { ReactComponent as IconSignOut } from '../../../images/icons/sign-out.svg'

function Icon(props) {

    function renderIcon(name) {
        switch(name) {
            case 'Home':
                return <IconHome className="icon icon-home" />;
            case 'AddOrder':
                return <IconAddOrder className="icon icon-add-order" />;
            case 'Orders':
                return <IconOrders className="icon icon-orders" />;
            case 'Download':
                return <IconDownload className="icon icon-download" />;
            case 'MyPages':
                return <IconMyPages className="icon icon-my-pages" />;
            case 'Economy':
                return <IconEconomy className="icon icon-economy" />;
            case 'Complaints':
                return <IconComplaints className="icon icon-complaints" />;
            case 'Media':
                return <IconMedia className="icon icon-media" />;
            case 'User':
                return <IconUser className="icon icon-user" />;
            case 'Account':
                return <IconAccount className="icon icon-account" />;
            case 'Password':
                return <IconPassword className="icon icon-password" />;
            case 'Settings':
                return <IconSettings className="icon icon-settings" />;
            case 'ShowMore':
                return <IconShowMore className="icon icon-show-more" />;
            case 'SignOut':
                return <IconSignOut className="icon icon-sign-out" />;
            default:
                return 'undefined';
        }
    }

    return (
        <span className="icon-wrap">
            {renderIcon(props.name)}
        </span>
    );
}

export default Icon;