import React from 'react';
import PageContent from '../../components/PageContent/';
import Login from '../../pages/Login/';
import OrderAdd from '../../pages/Orders/order-add';
import {
    Switch,
    Route,
    useLocation
} from "react-router-dom";

function App() {
    let
        isAuthenticated = localStorage.getItem('isAuthenticated') === 'true',
        location = useLocation();

    // Redirects to the login if not yet authenticated.
    if(!isAuthenticated && location.pathname !== '/login') {
        return window.location.replace('/login')
    }

    return (
        <Switch>
            <Route path="/login" component={Login} />
            <Route path="/order-add">
                <OrderAdd />
            </Route>
            <Route path="*">
                <PageContent pathname={location.pathname} />
            </Route>

        </Switch>
    );
}

export default App;