import React from 'react';
import ChartistGraph from 'react-chartist'; // https://gionkunz.github.io/chartist-js/index.html

let chart = {
    data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        series: [
            [250000, 150000, 180000, 130000, 100000, 90000, 130000, 250000, 300000, 350000, 250000, 230000],
            [200000, 100000, 190000, 120000, 90000, 80000, 125000, 230000, 250000, 300000, 240000, 220000]
        ]
    },
    options: {
        seriesBarDistance: 15
    },
    type: 'Bar'
}

class SalesFigures extends React.Component {

    render() {
        return (
            <div className="sales-figures">
                <div className="sales-numbers">
                    <div className="sales-items">
                        <div className="sales-item sales-total">
                            <h2>Totalt omsättning YTD</h2>
                            <div className="price">
                                <span className="value">2 256 790</span><span className="unit">kr</span>
                            </div>
                            <p>+4% jämfört med föregående år</p>
                        </div>
                        <div className="sales-item sales-contura">
                            <h2>Omsättning Contura YTD</h2>
                            <div className="price">
                                <span className="value">2 256 790</span><span className="unit">kr</span>
                            </div>
                            <p>+4% jämfört med föregående år</p>
                        </div>
                        <div className="sales-item sales-premodul">
                            <h2>Omsättning Premodul YTD</h2>
                            <div className="price">
                                <span className="value">677 037</span><span className="unit">kr</span>
                            </div>
                            <p>+7% jämfört med föregående år</p>
                        </div>
                    </div>
                </div>
                <div className="sales-chart">
                    <h2>Statistik över min butik</h2>
                    <ChartistGraph className={'ct-minor-seventh'} data={chart.data} options={chart.options} type={chart.type} />
                    <div className="sales-chart-footer">
                        <span className="chart-year chart-year-current"><span className="chart-color"></span> 2020</span>
                        <span className="chart-year chart-year-previous"><span className="chart-color"></span> 2019</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default SalesFigures;