import React from 'react';
import Table from '../../components/Table/';

const data = {
    header: [
        "Ordernummer",
        "Datum",
        "Försändelse ID",
        "Märkning",
        "Leverans från fabrik",
        "Summa",
    ],
    body: [
        {
            status: 'notice',
            to: '/test-file.pdf',
            items: [
                "SOB000223",
                "2019-12-10",
                "1235",
                "Mats Persson",
                "V3 2020",
                "5 000 SEK",
            ]
        },
        {
            to: '/test-file.pdf',
            items: [
                "SOB000223",
                "2019-12-10",
                "1235",
                "Mats Persson",
                "V3 2020",
                "5 000 SEK",
            ]
        },
        {
            to: '/test-file.pdf',
            items: [
                "SOB000223",
                "2019-12-10",
                "1235",
                "Mats Persson",
                "V3 2020",
                "5 000 SEK",
            ]
        },
    ]
};

class Orders extends React.Component {
    render() {
        return (
            <div className="main-content">

                <div className="primary-content">
                    <h1>Inneliggande ordrar</h1>

                    <div>
                        <div className="filter-table field field-select">
                            <select className="field-select-input">
                                <option>Filtrera</option>
                                <option>...</option>
                                <option>...</option>
                            </select>
                        </div>
                    </div>
                    <Table data={data} className="table-default" limit="2" />
                </div>

            </div>
        );
    }
}

export default Orders;