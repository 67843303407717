import React from 'react';

function ToggleMobileMenu() {

    function toggle() {
        document.documentElement.classList.toggle('has-visible-mobile-menu');
    }

    return (
        <div className="toggle-mobile-menu" onClick={toggle}>
            <span className="toggle-mobile-menu-icon"><span></span></span>
            <span className="toggle-mobile-menu-label">Meny</span>
        </div>
    );
}

export default ToggleMobileMenu;