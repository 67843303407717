import React from 'react';
import { ReactComponent as IconFileUpload } from '../../../images/icons/file-upload.svg'
import { ReactComponent as IconDelete } from '../../../images/icons/close.svg'

const SETTINGS = {
    placeholder: 'Ladda upp fil',
    formSubmitted: false
}

class Complaints extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...SETTINGS
        };
        this.getSelectedFile = this.getSelectedFile.bind(this);
        this.clearSelectedFile = this.clearSelectedFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getSelectedFile(event) {
        let selectedFile = event.target.value.split("\\").pop();
        if(selectedFile) {
            this.setState({placeholder: selectedFile});
        }
    }

    clearSelectedFile(event) {
        event.target.closest('.field-file').querySelector('.field-file-input').value = '';
        event.target.closest('.field-file').classList.toggle('field-file-has-selected-file');
        this.setState({placeholder: SETTINGS.placeholder});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({formSubmitted: true});
    }

    render() {
        return (
            <div className={`main-content ${this.state.formSubmitted ? 'complaints-submitted' : ''}`}>
                <div className="content-primary">
                    <h1>Skapa reklamation</h1>
                    <p>För att göra en teknisk reklamation, vänligen fyll i nedan formulär. Tänk på att ge en detaljerad felbeskrivning av produkten.</p>

                    <form className="form-complaints" onSubmit={this.handleSubmit}>
                        <div className="field field-select">
                            <select className="field-select-input">
                                <option>Välj produktkateori...</option>
                                <option>Kamin</option>
                                <option>Skorsten</option>
                                <option>Tillbehör</option>
                            </select>
                        </div>
                        <input type="text" className="field field-text" placeholder="Serienummer *" />
                        <input type="text" className="field field-text" placeholder="Antal" />
                        <textarea className="field field-textarea" placeholder="Felbeskrivning"></textarea>
                        <input type="text" className="field field-text" placeholder="Märkning" />
                        <div className={`field field-file ${this.state.placeholder !== 'Ladda upp fil' ? 'field-file-has-selected-file' : ''}`}>
                            <div className="field-file-button">
                                <input type="file" className="field-file-input" onChange={this.getSelectedFile} />
                                <span className="field-file-placeholder">{this.state.placeholder}</span>
                                <span className="field-file-icon field-file-icon-default"><IconFileUpload /></span>
                                <span className="field-file-icon field-file-icon-clear" onClick={this.clearSelectedFile}><IconDelete /></span>
                            </div>
                        </div>
                        <button type="submit" className="button-submit button-secondary">Skicka in</button>
                    </form>
                </div>
                <div className="content-primary content-primary-feedback">
                    <h1>TACK, VI HAR MOTTAGIT DIN REKLAMATION</h1>
                    <p>Följ status på din reklamation under Mina reklamationer.
                        Ditt ärende har reklamationsnummer: 555789006</p>
                    <button type="button" className="button-secondary" onClick={() => this.setState({ formSubmitted: false })}>SKAPA NY REKLAMATION</button>
                </div>
            </div>
        );
    }
}

export default Complaints;