import React from 'react';
import { ReactComponent as IconLiveDisplay } from '../../../images/icons/flame.svg'

class ProductList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.toggleConfirm = this.toggleConfirm.bind(this);
        this.addItem = this.addItem.bind(this);
    }

    handleChange(event) {
        let listProductItem = event.target.closest('.list-product-item');
        listProductItem.classList.toggle('list-product-item-is-live-display')
    }

    toggleConfirm(event) {
        event.target.closest('.buttonset-confirm').classList.toggle('buttonset-confirm-show-confirm')
    }

    addItem(event) {
        /*let
            item = event.target.closest('.list-product-item'),
            data = {
                id: item.dataset.id
            }*/

        this.props.toggleShowAddProducts();
        document.querySelector('.main-container').scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    deleteItem(event) {
        let
            item = event.target.closest('.list-product-item');
            /*data = {
                id: item.dataset.id
            }*/

        item.parentNode.removeChild(item);
    }

    listItems(props) {
        let
            add = props.add || false,
            uniqueId =  Math.random().toString(36).substr(2, 9);

        return props.items.map((item, index) =>
            <div key={index} className={`list-product-item ${item.isLiveDisplay ? 'list-product-item-is-live-display' : ''}`} data-id={item.id}>
                <div className="list-product-item-image">
                    <img src={require('../../../images/' + item.img)} alt="" />
                </div>
                <div className="list-product-item-content">
                    <h3 className="list-product-item-title">{item.title}</h3>
                    <p className="list-product-item-subtitle">Eldningsbar i butik</p>
                    <div className="list-product-item-select-live-display">
                        <input
                            className="field-checkbox field-is-live-display-yes"
                            type="radio"
                            id={`is-live-display-yes-${uniqueId}-${index}`}
                            name={`is-live-display-${uniqueId}-${index}`}
                            defaultChecked={item.isLiveDisplay}
                            onChange={this.handleChange}
                            value="1"
                        />
                        <label htmlFor={`is-live-display-yes-${uniqueId}-${index}`}>Ja</label>

                        <input
                            className="field-checkbox field-is-live-display-no"
                            type="radio"
                            id={`is-live-display-no-${uniqueId}-${index}`}
                            name={`is-live-display-${uniqueId}-${index}`}
                            defaultChecked={!item.isLiveDisplay}
                            onChange={this.handleChange}
                            value="0"
                        />
                        <label htmlFor={`is-live-display-no-${uniqueId}-${index}`}>Nej</label>
                    </div>
                    {
                        add ? (
                            <button type="button" className="button-sm button-secondary" onClick={this.addItem}>Lägg till</button>
                        ) : (
                            <div className="buttonset-confirm">
                                <button type="button" className="button-sm button-secondary" onClick={this.toggleConfirm}>Ta bort</button>
                                <div className="buttonset">
                                    <button type="button" className="button-sm button-tertiary" onClick={this.toggleConfirm}>Avbryt</button>
                                    <button type="button" className="button-sm button-delete"onClick={this.deleteItem}>Ta bort</button>
                                </div>
                            </div>
                        )
                    }
                    <div className="icon-live-display"><IconLiveDisplay /></div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="list-product">
                <div className="list-product-items">
                    {this.listItems(this.props)}
                </div>
            </div>
        );
    }
}

export default ProductList;