import React from 'react';
import { ReactComponent as IconSearch } from '../../../images/icons/search.svg'

class OrderTracking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            submitStatus: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            submitStatus: 'main-content-show-tracking-result'
        });
    }

    render() {
        return (
            <div className={`main-content ${this.state.submitStatus}`}>
                <div className="content-primary tracking-main">
                    <h1>Spåra leverans</h1>
                    <p>Här kan du spåra din leverans genom att ange försändelse-ID.</p>
                    <form className="form-order-tracking" onSubmit={this.handleSubmit}>
                        <div className="field-search">
                            <input type="search" className="field field-search-input" id="search" name="search" placeholder="Försändelse-ID" value={this.state.search} onChange={this.handleChange} />
                            <button type="submit" className="button-search"><IconSearch /></button>
                        </div>
                    </form>
                </div>

                <div className="content-primary tracking-result">
                    <h1>Din leverans</h1>
                    <button type="button" className="button-secondary" onClick={() => this.setState({ submitStatus: '' })}>Spåra ny försändelse</button>
                </div>
            </div>
        );
    }
}

export default OrderTracking;